import axios from 'axios'
import { TargetURL } from '../../Constants/TargetURL'
const token = localStorage.getItem('token')
const URL = new TargetURL()

const instance = axios.create({
  baseURL: URL.get(),
  headers: {
    'Content-type': 'application/json',
    Authorization: token ? `Bearer ${token}` : '',
  },
})

instance.interceptors.response.use(
  function (response) {
    //  if (process.env.NODE_ENV === 'development')
    //   console.log('log: ' + JSON.stringify(response)) 
    return response
  },
  function (error) {
    const Excluded = ['login', 'Reset', 'forgotPassword', 'PIN']
    const currentUrl = window.location.href
    var urlExceptuado = new RegExp(Excluded.join('|')).test(currentUrl)
    if (401 === error.response.status && !urlExceptuado) {
      localStorage.setItem('menu', '')
      localStorage.setItem('token', '')
      window.location.href = '/#/login'
    } else {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

/* instance.interceptors.request.use(
  function (config) {
    if (process.env.NODE_ENV === 'development')
      console.log('log: ' + JSON.stringify(config))
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
) */

instance.interceptors.request.use(
  (config) => {
    let token = window.localStorage.getItem('token')
    if (token) {
      config.headers!!.Authorization = `Bearer ${token}`
    } else {
      config.headers!!.Authorization = ``
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default instance
