import http from '../common/http-common'
import ILogin from '../../interfaces/Ilogin'
// import ItemMenuData from '../../Interfaces/Catalogos/IItemMenu'
import IPerfilesMenu from '../../interfaces/IPerfilesMenu'
import { IPerfil } from '../../interfaces/Catalogos/IPerfiles'
// import ITransportista from '../../Interfaces/Catalogos/ITransportitas'
// import IProveedores from '../../Interfaces/Catalogos/IProveedores'
import DTOPerfilCreate from '../../DTO/DTOPerfilCreate'
import DTOItemMenuPerfil from '../../DTO/DTOItemMenuPerfil'
import IItemMenu from '../../interfaces/Catalogos/IItemMenu'

//import DTOasignaClienteProveedor from '../DTO/DTOasignaClienteProveedor'

class PerfilesMenuService {
  /**
   *
   */
  constructor() {
    // super();
    type token = string
    type returnData = [IPerfilesMenu[]]
  }

  getAllPerfiles() {
    return http.get<IPerfil[]>('/Perfiles/getPerfiles')
  }
  getMenu() {
    return http.get<IPerfilesMenu[]>('/Perfiles/getMenu')
  }
  getPerfilMenuById(id: number) {
    return http.get<IPerfilesMenu[]>(`/Perfiles/getPerfilMenuById?id=${id}`)
  }
  getAllPerfilesMenu() {
    return http.get<IPerfilesMenu[]>('/Perfiles/getAllPerfilesMenu')
  }
  // getAllTransportitas(id: number) {
  //   return http.get<ITransportista[]>(`/Perfiles/getAllTransportistas?id=${id}`)
  // }
  // getAllProveedores(id: number) {
  //   return http.get<IProveedores[]>(`/Perfiles/getAllProveedores/?id=${id}`)
  // }
  createItemMenu(data: IItemMenu) {
    return http.post<[IItemMenu]>('/Perfiles/createItemMenu', data)
  }
  createPerfil(data: DTOPerfilCreate) {
    return http.post<[DTOPerfilCreate[]]>('/Perfiles/createPerfil', data)
  }
  toggleItemMenu(data: DTOItemMenuPerfil) {
    return http.post<[DTOItemMenuPerfil[]]>('/Perfiles/asignaItemMenu', data)
  }
}
export default new PerfilesMenuService()
