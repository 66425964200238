import http from  '../../Services/common/http-common'

class uploadExel{

    uploadBook(formDataBook: FormData) {
        return http.post('/Excel/uploadBook', formDataBook, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }


    uploadPrecta(formDataBook: FormData) {
        return http.post('/Excel/uploadPrecta', formDataBook, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }


    uploadWorkingHours(formDataBook: FormData) 
    {
        return http.post('/Excel/UploadWorkingHours', formDataBook, {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        } )
    }

    

}
export default new uploadExel()