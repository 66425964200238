import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logued } from '../../Store/features/userStatusSlice/userStatusSlice'

interface IProps {}

const Logout: FC<IProps> = (props) => {
  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    window.localStorage.clear()
    dispatch(logued(false))
    navigate('/')
  })

  return <div></div>
}

export default Logout
