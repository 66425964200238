import * as React from 'react'
import { useState } from 'react'
import { Button, Card, CardBody, Form, Modal,  Col } from 'react-bootstrap'
import * as XLSX from 'xlsx'
import DataTable, { TableColumn } from 'react-data-table-component';
import { MsgInformativo } from '../../Utils/Toast/msgInformativo';
import reportesServices from '../../Services/Reportes/reportes.services';
import ExelServices from '../../Utils/ExelFiles/Exel.services';
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
import { FiSend } from "react-icons/fi";
import { DTORelPreChe } from '../../DTO/DTORelPreChe';
import { blob } from 'node:stream/consumers';
export interface IFileUploadExelProps {}

export default function FileUploadExel(props : IFileUploadExelProps) {
  const [bookFile, setBookFile] = useState<File | null>(null)
  const [prectaFile, setPrectaFile] = useState<File | null>(null)
  const [showModal, setShowModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [message, setMessage] = useState<string | null>(null);
  const [Data, setData] = useState<Array<DTORelPreChe>>([])
  const [filteredData, setFilteredData] = useState<Array<DTORelPreChe>>(
    
    []
  )


  
  const [precuenta, setpPrecuenta] = useState('')
  const [cheque, setCheque] = useState('')
  const [show, setShowMsg] = useState(false)
  const [header, setHeader] = useState('')
  const [msg, setMsg] = useState('')
  const [filtro, setFiltro] = useState('')
  const [msgColor, setMsgColor] = React.useState('primary')

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    const file = event.target.files?.[0] || null
    setFile(file)
  }

  

  const handleRemoveFile = (file: File, setFile: React.Dispatch<React.SetStateAction<File | null>>) => {
    setFile(null)
    setMessage('Archivo eliminado exitosamente')
  }

 
const handleSendFiles = async () => {
  if (!bookFile && !prectaFile) {
      setMessage('No hay archivos para enviar');
      return;
  }

  // Crear FormData para los archivos
  const formDataBook = new FormData();
  if (bookFile) {
      formDataBook.append('file', bookFile);
  }

  const formDataPrecta = new FormData();
  if (prectaFile) {
      formDataPrecta.append('file', prectaFile);
  }

  try {
      // Enviar archivo Book
      if (bookFile) {
          const responseBook = await ExelServices.uploadBook(formDataBook);

          if (!responseBook || responseBook.status !== 200) {
              throw new Error('Error al subir el archivo Book');
          }

          console.log('Archivo Book subido exitosamente:', responseBook.data);
      }

      // Enviar archivo Precta
      if (prectaFile) {
          const responsePrecta = await ExelServices.uploadPrecta(formDataPrecta);

          if (!responsePrecta || responsePrecta.status !== 200) {
              throw new Error('Error al subir el archivo Precta');
          }

          console.log('Archivo Precta subido exitosamente:', responsePrecta.data);
      }

      setMessage('Archivos enviados exitosamente');

        setBookFile(null);
        setPrectaFile(null);
        setTimeout(() => {
          setMessage(null);
        }, 4000);
      


  } catch (error) {
      const errorMessage = (error as Error).message || 'Error desconocido';
      setMessage(`Error al enviar archivos: ${errorMessage}`);
  }
};


const closeToast = (show: boolean): void => {
  setShowMsg(false)
}




const columnDefs= [
  {
    name: <div style={{ textAlign: 'center' }}>Type</div>,
    width: '70px',
    selector: (row: DTORelPreChe) => row.type,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Cheque</div>,
    width: '100px',
    selector: (row: DTORelPreChe) => row.cheques,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Date</div>,
    width: '100px',
    selector: (row: DTORelPreChe) => row.date,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Num</div>,
    width: '130px',
    selector: (row: DTORelPreChe) => row.num,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Memo</div>,
    width: '200px',
    selector: (row: DTORelPreChe) => row.memo,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Amount</div>,
    width: '150px',
    selector: (row: DTORelPreChe) => row.amount,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Trafico</div>,
    width: '150px',
    selector: (row: DTORelPreChe) => row.trafico,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Trafico1</div>, // Cambié el nombre para evitar duplicados
    width: '100px',
    selector: (row: DTORelPreChe) => row.trafico1,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Precuenta</div>,
    width: '100px',
    selector: (row: DTORelPreChe) => row.precuenta,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Concepto</div>,
    width: '150px',
    selector: (row: DTORelPreChe) => row.concepto,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Importe</div>,
    width: '100px',
    selector: (row: DTORelPreChe) => row.importe,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Cantidad</div>,
    width: '100px',
    selector: (row: DTORelPreChe) => row.cantidad,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Tipo</div>,
    width: '100px',
    selector: (row: DTORelPreChe) => row.tipo,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>IdProvedor</div>,
    width: '100px',
    selector: (row: DTORelPreChe) => row.idProvedor,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>NomProvedor</div>,
    width: '150px',
    selector: (row: DTORelPreChe) => row.nomProvedor,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>TagTabulador</div>,
    width: '120px',
    selector: (row: DTORelPreChe) => row.tagTabulador,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>NombreTabulador</div>,
    width: '100px',
    selector: (row: DTORelPreChe) => row.nombreTabulador,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Rctadeb</div>,
    width: '150px',
    selector: (row: DTORelPreChe) => row.rctadeb,
    sortable: true,
    filter: true,
    center: true
  },
  {
    name: <div style={{ textAlign: 'center' }}>Diferencia</div>,
    width: '150px',
    selector: (row: DTORelPreChe) => row.diferencia,
    sortable: true,
    filter: true,
    center: true
  }
];




// const generaReporte = () => {

//   reportesServices
//   .getRptRelacionPreChe(precuenta, cheque)
//   .then((reponse) => {
//     setData(reponse.data) 
//     setFilteredData(reponse.data)

//       setHeader('informativo')
//       setMsg('Se encontro la siguiente informacion...')
//       setShowMsg(true)
//       })
//       .catch((e: Error)  => {
//         setHeader('Error')
//         setMsg('Ocurrio un error' + e.message  )
//         setShowMsg(true)
//         return 
//       })

// }






  const [selectedOption, setSelectedOption] = useState<string>('Relacion'); // Valor inicial del ComboBox

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
  };

  const generaReporte = () => {
    switch (selectedOption) {
      case 'Relacion':
        reportesServices
          .getRptRelacionPreChe(precuenta, cheque)
          .then((response) => {
            setData(response.data);
            setFilteredData(response.data);
            setHeader('Informativo');
            setMsg('Se encontró la siguiente información...');
            setShowMsg(true);
          })
          .catch((e: Error) => {
            setHeader('Error');
            setMsg('Ocurrió un error: ' + e.message);
            setShowMsg(true);
          });
        break;

      case 'Trafico':
        reportesServices
          .getRptRelacionesTrafico()
          .then((response: any) => {
            // Maneja la respuesta de Trafico
            setData(response.data);
            setFilteredData(response.data);
            setHeader('Informativo');
            setMsg('Se encontró la siguiente información...');
            setShowMsg(true);
          })
          .catch((e: Error) => {
            setHeader('Error');
            setMsg('Ocurrió un error: ' + e.message);
            setShowMsg(true);
          });
        break;

      case 'NoRelaciones':
        reportesServices
          .getRptNoRelaciones()
          .then((response: any) => {
            // Maneja la respuesta de No Relacionados
            setData(response.data);
            setFilteredData(response.data);
            setHeader('Informativo');
            setMsg('Se encontró la siguiente información...');
            setShowMsg(true);
          })
          .catch((e: Error) => {
            setHeader('Error');
            setMsg('Ocurrió un error: ' + e.message);
            setShowMsg(true);
          });
        break;

      default:
        setHeader('Error');
        setMsg('Tipo de reporte no válido');
        setShowMsg(true);
        break;
    }
  };

 
    // 


// const downloadExcel = () => {
  

//   switch(selectedOption){


//     case 'Relacion':
//     exportExcel(Data, 'Datos Que se Relacionan'); 
//     break; 

//     case 'Trafico' : 
//     exportExcel(Data, 'Relaciones Solo Trafico')
//     break; 

//     case 'NoRelaciones':
//     exportExcel(Data, 'No Se Relacionan Datos')
//     break

//     default: 
//     setHeader('Error') 
//       setMsg('No Valido'); 
//       setShowMsg(true); 
//       break; 
    

//   }

//   // exportExcel(Data, 'Datos Tabla');
// };

// function exportExcel(
//   jsonData: DTORelPreChe[],
//   fileName: string
// ): void {
//   let Heading = [
//     [
//       'Type',
//       'Cheques',
//       'Date',
//       'Num',
//       'Name',
//       'Memo',
//       'Amount',
//       'Trafico',
//       'Trafico',
//       'Precuenta',
//       'Concepto',
//       'Importe',
//       'Cantidad',
//       'Tipo',
//       'IdProvedor',
//       'NomProvedor',
//       'Tagtabulador',
//       'NombreTabulador',
//       'Rctadeb',
//       'Diferencia'
//     ]
//   ];

//   const wb = XLSX.utils.book_new();
//   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

//   // Añadir encabezados
//   XLSX.utils.sheet_add_aoa(ws, Heading);

//   // Añadir datos
//   XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true });

//   // Asegurarse de que la referencia esté correctamente definida
//   ws['!ref'] = XLSX.utils.encode_range({
//     s: { c: 0, r: 0 },
//     e: { c: Heading[0].length - 1, r: jsonData.length + 1 }
//   });

//   // Convertir encabezados a mayúsculas
//   const range = XLSX.utils.decode_range(ws['!ref']);
//   for (let C = range.s.c; C <= range.e.c; ++C) {
//     const address = XLSX.utils.encode_col(C) + '1'; // <-- primera fila, columna número C
//     if (!ws[address]) continue;
//     ws[address].v = ws[address].v.toUpperCase();
//   }

//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//   XLSX.writeFile(wb, fileName + '.xlsx');
// }




const downloadExcel = () => {
  switch(selectedOption){
    case 'Relacion':
      exportExcel(filteredData, 'Datos Que se Relacionan', 'Reporte de Relaciones'); 
      break; 

    case 'Trafico':
      exportExcel(filteredData, 'Relaciones Solo Trafico', 'Reporte de Tráfico');
      break; 

    case 'NoRelaciones':
      exportExcel(filteredData, 'No Se Relacionan Datos', 'Reporte de No Relaciones');
      break;

    default: 
      setHeader('Error'); 
      setMsg('No Válido'); 
      setShowMsg(true); 
      break; 
  }
};

function exportExcel(
  jsonData: DTORelPreChe[],
  fileName: string,
  reportTitle: string
): void {
  const Heading = [
    [
      'Type',
      'Cheques',
      'Date',
      'Num',
      'Name',
      'Memo',
      'Amount',
      'Trafico',
      'Trafico',
      'Precuenta',
      'Concepto',
      'Importe',
      'Cantidad',
      'Tipo',
      'IdProvedor',
      'NomProvedor',
      'Tagtabulador',
      'NombreTabulador',
      'Rctadeb',
      'Diferencia'
    ]
  ];

  const wb = XLSX.utils.book_new();
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  // Añadir el título del reporte
  XLSX.utils.sheet_add_aoa(ws, [[reportTitle]], { origin: 'A1' });

  // Fusionar celdas para que el título cubra todas las columnas
  ws['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: Heading[0].length - 1 } }
  ];

  // Aplicar estilo al título
  const titleCell = ws['A1'];
  if (titleCell) {
    titleCell.s = {
      font: { bold: true, sz: 18, color: { rgb: 'FFFFFF' } },
      alignment: { horizontal: 'center', vertical: 'center' },
      fill: { fgColor: { rgb: '4F81BD' } },
      border: {
        top: { style: 'thick', color: { rgb: '000000' } },
        bottom: { style: 'thick', color: { rgb: '000000' } },
        left: { style: 'thick', color: { rgb: '000000' } },
        right: { style: 'thick', color: { rgb: '000000' } }
      }
    };
  }

  // Añadir encabezados
  XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A2' });

  // Añadir datos filtrados
  XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A3', skipHeader: true });

  // Asegurarse de que la referencia esté correctamente definida
  ws['!ref'] = XLSX.utils.encode_range({
    s: { c: 0, r: 0 },
    e: { c: Heading[0].length - 1, r: jsonData.length + 2 }
  });

  // Convertir encabezados a mayúsculas
  const range = XLSX.utils.decode_range(ws['!ref']);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const address = XLSX.utils.encode_col(C) + '2';
    if (!ws[address]) continue;
    ws[address].v = ws[address].v.toUpperCase();
  }

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, fileName + '.xlsx');
}




const filtraReporte = (e: any) => {
  const searchText = e.target.value.toLowerCase();
  setFiltro(searchText);

  const filtered = Data.filter((item) => {
    return (
      (item.type && item.type.toLowerCase().includes(searchText)) ||
      (item.cheques && item.cheques.toString().toLowerCase().includes(searchText)) ||
      (item.date && item.date.toString().toLowerCase().includes(searchText)) ||
      (item.num && item.num.toString().toLowerCase().includes(searchText)) ||
      (item.name && item.name.toString().toLowerCase().includes(searchText)) ||
      (item.memo && item.memo.toString().toLowerCase().includes(searchText)) ||
      (item.amount && item.amount.toString().toLowerCase().includes(searchText)) ||
      (item.trafico && item.trafico.toString().toLowerCase().includes(searchText)) ||
      (item.precuenta && item.precuenta.toString().toLowerCase().includes(searchText)) ||
      (item.concepto && item.concepto.toString().toLowerCase().includes(searchText)) ||
      (item.importe && item.importe.toString().toLowerCase().includes(searchText)) ||
      (item.cantidad && item.cantidad.toString().toLowerCase().includes(searchText)) ||
      (item.tipo && item.tipo.toString().toLowerCase().includes(searchText)) ||
      (item.idProvedor && item.idProvedor.toString().toLowerCase().includes(searchText)) ||
      (item.nomProvedor && item.nomProvedor.toString().toLowerCase().includes(searchText)) ||
      (item.tagTabulador && item.tagTabulador.toString().toLowerCase().includes(searchText)) ||
      (item.nombreTabulador && item.nombreTabulador.toString().toLowerCase().includes(searchText)) ||
      (item.rctadeb && item.rctadeb.toString().toLowerCase().includes(searchText)) ||
      (item.diferencia && item.diferencia.toString().toLowerCase().includes(searchText))
    );
  });

  console.log('Filtered Data:', filtered);

  setFilteredData(filtered);
}


  const handleOpenModal = (file: File) => {
    setSelectedFile(file)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setSelectedFile(null)
    setShowModal(false)
  }

  const handleDeleteFile = () => {
    if (selectedFile) {
      if (selectedFile === bookFile) {
        handleRemoveFile(selectedFile, setBookFile)
      } else if (selectedFile === prectaFile) {
        handleRemoveFile(selectedFile, setPrectaFile)
      }
      setSelectedFile(null)
      setShowModal(false)
    }
  }

  return (
    <div className="mt-2">
      <Card>
        <Card.Body>
          <Form>
            <div className="d-flex justify-content-around">
              <Card
                className="text-center p-4 m-2"
                style={{ backgroundColor: 'lightgreen', borderRadius: '8px', width: '45%' }}
              >
                <Form.Group>
                  <Form.Label className="d-block mb-2" style={{ fontWeight: 'bold' }}>
                    Arrastra o selecciona el archivo Book
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={(e) => handleFileChange(e as React.ChangeEvent<HTMLInputElement>, setBookFile)}
                    style={{ display: 'none' }}
                    id="bookFileInput"
                  />
                  <label
                    htmlFor="bookFileInput"
                    className="btn btn-light d-block"
                    style={{ cursor: 'pointer', border: '2px solid green', borderRadius: '8px' }}
                  >
                    {bookFile ? bookFile.name : 'Seleccionar archivo'}
                  </label>
                </Form.Group>
              </Card>

              <Card
                className="text-center p-4 m-2"
                style={{ backgroundColor: 'lightcoral', borderRadius: '8px', width: '45%' }}
              >
                <Form.Group>
                  <Form.Label className="d-block mb-2" style={{ fontWeight: 'bold' }}>
                    Arrastra o selecciona el archivo Precta
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={(e) => handleFileChange(e as React.ChangeEvent<HTMLInputElement>, setPrectaFile)}
                    style={{ display: 'none' }}
                    id="prectaFileInput"
                  />
                  <label
                    htmlFor="prectaFileInput"
                    className="btn btn-light d-block"
                    style={{ cursor: 'pointer', border: '2px solid red', borderRadius: '8px' }}
                  >
                    {prectaFile ? prectaFile.name : 'Seleccionar archivo'}
                  </label>
                </Form.Group>
              </Card>
            </div>

            <Button
              variant="success"
              onClick={handleSendFiles}
              style={{ marginTop: '10px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
            >
              Enviar <FiSend />
            </Button>
            {message && <p className="mt-2">{message}</p>}
          </Form>

          <div className='row'>  
          <div className='col-md-1' style={{width: '5px'}}></div>
          <div className='col-md-1 d-flex align-items-center' style={{ width: '300px'  }}>
          <select 
            value={selectedOption} 
            onChange={handleOptionChange} 
            className='form-select me-2' 
            style={{ width: 'auto' }}
          >
            <option value="Relacion">Relacion</option>
            <option value="Trafico">Trafico</option>
            <option value="NoRelaciones">No Relacionado</option>
          </select>
          <Button 
            variant='primary' 
            size="sm" 
            onClick={generaReporte}
          >
            Buscar <BsSearch />
          </Button> 
        </div>

              {/* <div className='col-md-4' style={{width: '120px'}}>
                  <Button 
                    variant='primary'
                    size= "sm"
                    onClick={() =>  {
                      generaReporte()
                    }}
                  >
                    Buscar <BsSearch /> 
                    </Button>



            
              
              
          </div> */}

          

          
        

          <div className='col-md-1' style={{width: 'auto'}}></div>
          <div className='col-md-4'>
            <Form.Control 
            type='text'
            size="sm"
            placeholder='Search...'
            onChange={(e)  => {
              filtraReporte(e)
            }  }
            />
            </div>


            <div className="col-md-1" style={{ width: '400px' }}></div>
            <div className="col-md-1">
              <Button 
              style={{ width: 'auto', whiteSpace: 'nowrap' }}
              size='sm'
              variant='success'
              onClick={() => {
                downloadExcel();
              }}
            >
              Excel <BsFileEarmarkExcel />
            </Button>
            </div>


              



            
          </div>
        </Card.Body>
      </Card>

      <Card>



        <Card.Body>
          <div 
          className='ag-theme-alpine'
          style={{ height: 400, width: '100%', textAlign: 'center'}}
          >

          <DataTable 
          columns={columnDefs}
          data={filteredData}
          defaultSortAsc={true}
          striped={true}
          dense= {true}
          paginationPerPage={10}
          pagination
          highlightOnHover
          pointerOnHover
          style={{ height: 300, width: '100%',  textAlign: 'center'}}
          onRowClicked={(row ) =>  {

          }}
          />
          </div>
        </Card.Body>
      </Card>
      {selectedFile && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedFile.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Contenido del archivo:</p>
            {/* Aquí puedes añadir la lógica para mostrar el contenido del archivo si es necesario */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cerrar
            </Button>
            <Button variant="danger" onClick={handleDeleteFile}>
              Eliminar Archivo
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={closeToast}
      />

    </div>
  )
}


