import http from '../../../Services/common/http-common'
import ICatNotificaciones from '../interfaces/ICatNotificaciones'
//import I1868CatResponsablesCorreos from '../Interfaces/I1868CatResponsablesCorreos'

class CatNotificacionesDataServices {
  Get() {
    return http.get<ICatNotificaciones[]>(`/CatNotificaciones/CatNotificaciones/Get`)
  }
  Append(data: ICatNotificaciones) {
    return http.post<ICatNotificaciones>(
      `/CatNotificaciones/CatNotificaciones/Append`,
      data
    )
  }
/*   GetByResponsable(id: number) {
    return http.get<I1868CatResponsablesCorreos[]>(
      `/CatTransportistas/GetByResponsable?IdResponsable=${id}`
    )
  }
  AppendData(data: I1868CatResponsablesCorreos) {
    return http.post<I1868CatResponsablesCorreos[]>(
      `/CatTransportistas/AppendData`,
      data
    )
  } */
  DeleteEmail(id: number) {
    return http.delete<Boolean>(`/CatTransportistas/CatNotificaciones/DeleteEmail?id=${id}`)
  }
}
export default new CatNotificacionesDataServices()