import React, { FC, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { propTypes } from 'react-bootstrap/esm/Image'
import { IPerfil } from '../../../../interfaces/Catalogos/IPerfiles'
import perfilesMenuServices from '../../../../Services/Catalogos/PerfilesMenu.Services'
import DTOPerfilCreate from '../../../../DTO/DTOPerfilCreate'

interface IProps {
  show: boolean
  CloseModalPerfiles: (arg: boolean) => void
  createProfile: (value: true) => void
}

export const NuevoPerfil: FC<IProps> = (props) => {
  const [IdPerfil, setIdPerfil] = React.useState(0)
  const [nombrePerfil, setnombrePerfil] = React.useState('')
  const [Perfiles, setPerfiles] = React.useState<IPerfil[]>()
  useEffect(() => {
    perfilesMenuServices
      .getAllPerfiles()
      .then((response) => {
        setPerfiles(response.data)
        console.log(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }, [props.show])

  function validatePerfil() {
    if (!nombrePerfil.length) alert('Proporcione el nombre del perfil')
    const data: DTOPerfilCreate = {
      Perfil: nombrePerfil,
      IdPerfilClonado: IdPerfil,
    }
    perfilesMenuServices
      .createPerfil(data)
      .then((response) => {
        if (response.status == 200) alert('El perfil se creo existosamente')
        console.log(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }

  const onChangePerfil = (e: any): void => {
    setIdPerfil(e.currentTarget.value)
  }

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Nuevo perfil</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <form>
            <div className='mb-3'>
              <label className='form-label'>
                Clona informacion del perfil:
              </label>
              <select
                className='form-select'
                aria-label='Perfiles'
                onChange={onChangePerfil}
              >
                {Perfiles?.map((p) => {
                  return <option value={p.id}>{p.perfil}</option>
                })}
              </select>
            </div>
            <div className='mb-3'>
              <label className='form-label'>Nombre</label>
              <input
                type='email'
                className='form-control'
                id='exampleInputEmail1'
                aria-describedby='emailHelp'
                onChange={(e) => {
                  setnombrePerfil(e.currentTarget.value)
                }}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='row'>
          <div className='col'>
            <Button
              variant='secondary'
              onClick={() => {
                props.CloseModalPerfiles(false)
              }}
            >
              Cerrar
            </Button>
          </div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'>
            <Button
              variant='primary'
              onClick={() => {
                //props.createProfile(true);
                validatePerfil()
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
