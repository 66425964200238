import http from '../common/http-common'
import DTORelacionBookPrecta from '../../Componest/Catalogos/CatRelacionBookPrecta/DTO/DTORelacionBookPrecta'
import DtoPrecuentaCheques from '../../Componest/Catalogos/CatRelacionBookPrecta/DTO/DTOPrecuentaCheques'

class RelacionChequesPrecuenta{

    
  GETRelacionBookPrecta(Inicio: string, Fin: string){
    return http.get<DTORelacionBookPrecta[]>(`/CatalogoRelaciones/GetAll?Inicio=${Inicio}&Fin=${Fin}`)

  }

  GETRelacionBookPrectaPreChe(Precuenta:string, Cheques: string)
  {
    return http.get<DTORelacionBookPrecta[]>(`/Catalogos/CatRelacionesBookPrecta/GetAllPreChe?Precuenta=${Precuenta}&Cheques=${Cheques}`)
  }

  PostAppedPrecuentaCheques(Precuenta: string, Cheques: string )
  {
    return http.post<DtoPrecuentaCheques[]> (`/Catalogos/CatRelacionesBookPrecta/PrecuentaChequesAppend?Precuenta=${Precuenta}&Cheques=${Cheques}`)
  }


  GetAllPrecuentaCheques()
  {
    return http.get <DtoPrecuentaCheques[]> ('/Catalogos/CatRelacionesBookPrecta/GetAllPrecuentaCheques')
  }

  DeletePrecuentaCheques(Precuenta: string)
  {
    return http.delete <DtoPrecuentaCheques[]> (`/Catalogos/CatRelacionesBookPrecta/DeleteAllPrecuentaCheques?Precuenta=${Precuenta}`)
  }

}


export default  new RelacionChequesPrecuenta()