import http from "../common/http-common";
import { IRegister } from "../../interfaces/IRegister";
import DTOUsuariosShort from "../../DTO/DTOUsuariosShort";
import DTOClonarUsuario from "../../DTO/DTOClonarUsuario";
import DTOUsuario from "../../DTO/DTOUsuario";
import { IUsuario } from "../../interfaces/IUsuario";


class usuariosService {
  constructor() {
  }
  validate() {
    return http.get<null>("/Auth/validate");
  }
  getAllUsuarios() {
    return http.get<IRegister[]>("/Usuarios/getAllUsuarios");
  }
  getAllUsuariosShort() {
    return http.get<DTOUsuariosShort[]>("/Usuarios/getAllUsuariosShort");
  }
  create(data: IUsuario) {
    return http.post<[IRegister[]]>("/Usuarios/createUsuario", data);
  }
  clonarUsuario(data: DTOClonarUsuario) {
    return http.post<[DTOClonarUsuario[]]>("/Usuarios/clonarUsuario", data);
  }
  disableUser(id: number) {
    return http.put<boolean>(`/Usuarios/disableUser?id=${id}`);
  }

 /* get(id: string) {
    return http.get<ITutorialData>(`/tutorials/${id}`);
  }
  create(data: ILogin) {
    return http.post<[ItemMenuData[]]>("/Auth", data);
  }
  update(data: ITutorialData, id: any) {
    return http.put<any>(`/tutorials/${id}`, data);
  }
  delete(id: any) {
    return http.delete<any>(`/tutorials/${id}`);
  }
  deleteAll() {
    return http.delete<any>(`/tutorials`);
  }
  findByTitle(title: string) {
    return http.get<Array<ITutorialData>>(`/tutorials?title=${title}`);
  }*/
}
export default new usuariosService();