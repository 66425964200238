import * as React from 'react'
import { useEffect, useState, useRef } from 'react'
import RelacionPrecuentaCheques from '../../../Services/Catalogos/RelacionPrecuentaCheques'
import { Button, Card, Form, Row, Col, Modal } from 'react-bootstrap'
import { BsSearch, BsFileEarmarkExcel } from 'react-icons/bs'
import { CgAdd } from 'react-icons/cg'
import { useSelector } from 'react-redux'
import { RootState } from '../../../Store/store'
import * as XLSX from 'xlsx'
import DataTable from 'react-data-table-component'
import DtoPrecuentaCheques from './DTO/DTOPrecuentaCheques'
import { MsgInformativo } from '../../../Utils/Toast/msgInformativo'
export interface IRptRelacionBookPrectaProps {}
export default function CatRelacionBookPrecta(
  props: IRptRelacionBookPrectaProps
) {
  const mInvoices = useSelector(
    (state: RootState) => state.CatRelacionBookPrectaSlice
  )

  const [filtro, setFiltro] = useState('')
  const [Data, setData] = useState<Array<DtoPrecuentaCheques>>([])
  const [filteredData, setFilteredData] = useState<Array<DtoPrecuentaCheques>>(
    []
  )
  const [show, setShowMsg] = useState(false)
  const [msg, setMsg] = useState('')
  const [header, setHeader] = useState('')
  const [Precuenta, setPrecuenta] = useState('')
  const [Cheques, setCheques] = useState('')
  const [TipoOperacion, setTipoOperacion] = useState(1)
  // const [msgColor, setMsgColor] = useState('')
  const msgColor = 'primary'
  const gridRef = useRef<any>(null)

  const [showModal, setShowModal] = useState(false)
  const [newPrecuenta, setNewPrecuenta] = useState('')
  const [newCheques, setNewCheques] = useState('')

  const columnsRelacionBookPrecta = [
    {
      name: 'Precuenta',
      width: '40%',
      selector: (row: DtoPrecuentaCheques) => row.precuenta,
      sortable: true
    },
    {
      name: 'Cheques',
      width: '40%',
      selector: (row: DtoPrecuentaCheques) => row.cheques,
      sortable: true
    },
    {
      name: 'Acciones',
      width: '5%',
      cell: (row: DtoPrecuentaCheques) => (
        <Button
          size="sm"
          variant="danger"
          onClick={() => handleDelete(row.precuenta)}
        >
          X
        </Button>
      )
    }
  ]

  //comboc box para editar con precuenta
  const generarReporte = () => {
    const parametros = {
      precuenta: TipoOperacion === 1 ? Precuenta : Cheques,
      cheques: TipoOperacion === 2 ? Precuenta : Cheques
    }

    const { precuenta, cheques } = parametros

    RelacionPrecuentaCheques.GETRelacionBookPrectaPreChe(
      precuenta || '',
      cheques || ''
    )
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
        setHeader('Informativo')
        setMsg('Se encontró la siguiente información...')
        setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(`Ocurrió un error: ${e}`)
        setShowMsg(true)
      })
  }

  const generarReporte1 = () => {
    RelacionPrecuentaCheques.GetAllPrecuentaCheques()
      .then((response) => {
        setData(response.data)
        setFilteredData(response.data)
        // setHeader('Informativo')
        // setMsg('Se encontró la siguiente información...')
        // setShowMsg(true)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(`Ocurrió un error: ${e}`)
        setShowMsg(true)
      })
  }

  // useEffect(() => {
  //   generarReporte1()
  // }, [])

  const exportExcel = (jsonData: any[], fileName: string) => {
    const Heading = [
      [
        // 'Type',

        // 'Date',
        // 'Num',
        // 'Name',
        // 'Memo',
        // 'Amount',
        // 'Trafico',
        // 'Trafico1',
        'id',
        'Precuenta',
        'Cheques'
        // 'Concepto',
        // 'Importe',
        // 'Cantidad',
        // 'Tipo',
        // 'IdProvedor',
        // 'NomProvedor',
        // 'TagTabulador',
        // 'NombreTabulador',
        // 'Rctadeb',
        // 'Diferencia'
      ]
    ]

    const wb = XLSX.utils.book_new()
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, Heading)
    XLSX.utils.sheet_add_json(ws, jsonData, { origin: 'A2', skipHeader: true })
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
    XLSX.writeFile(wb, `${fileName}.xlsx`)
    const range = XLSX.utils.decode_range(ws['!ref']!)
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const address = XLSX.utils.encode_col(C) + '1'
      if (!ws[address]) continue
      ws[address].v = ws[address].v.toUpperCase()
    }
  }

  const filtraReporte = (e: any) => {
    setFiltro(e.target.value)
    const filtered = Data.filter(
      (item) =>
        item.precuenta.toLowerCase().includes(e.target.value.toLowerCase()) ||
        item.cheques.toLowerCase().includes(e.target.value.toLowerCase())
    )
    setFilteredData(filtered)
  }

  const downloadExcel = () => exportExcel(Data, 'RelacionesBookPrecta')

  const precuentaExists = (precuenta: string) => {
    return Data.some((item) => item.precuenta === precuenta)
  }
  const handleSave = () => {
    if (newPrecuenta === newCheques) {
      setHeader('Informativo')
      setMsg('No puede introducir precuentas y cheques iguales')
      setShowMsg(true)
      return
    }

    if (precuentaExists(newPrecuenta)) {
      setHeader('Informativo')
      setMsg('La precuenta ya existe en los registros')
      setShowMsg(true)
      return
    }

    RelacionPrecuentaCheques.PostAppedPrecuentaCheques(newPrecuenta, newCheques)
      .then((response) => {
        setHeader('Informativo')
        setMsg('Se ha agregado la nueva relación exitosamente.')
        setShowMsg(true)
        setNewPrecuenta('')
        setNewCheques('')
        generarReporte1()
        setShowModal(false)
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(`Ocurrió un error: ${e}`)
        setShowMsg(true)
      })
  }

  const handleOpenModal = () => {
    setNewPrecuenta('')
    setNewCheques('')
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setNewPrecuenta('')
    setNewCheques('')
    setShowModal(false)
  }

  const handleDelete = (precuenta: string) => {
    RelacionPrecuentaCheques.DeletePrecuentaCheques(precuenta)
      .then((response) => {
        setHeader('Eliminado')
        setMsg('Se ha eliminado la relación exitosamente.')
        setShowMsg(true)
        generarReporte1()
      })
      .catch((e: Error) => {
        setHeader('Error')
        setMsg(`Ocurrió un error: ${e}`)
        setShowMsg(true)
      })
  }

  return (
    <div className="mt-2">
      <Card>
        <Card.Body>
          <div className="container">
            <div
              className="row mb-4"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '1vh'
              }}
            >
              <Card
                style={{
                  paddingTop: '10px',
                  paddingBottom: '5px',
                  width: '800px',
                  height: 'auto'
                }}
              >
                <Row className="align-items-center">
                  <Col xs={12}>
                    <Row className="align-items-center">
                      {/* <Col xs={1} style={{ textAlign: 'right' }}>
                        <Form.Label className="mb-0">Codigo</Form.Label>
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          size="sm"
                          value={Precuenta}
                          onChange={(e) => setPrecuenta(e.target.value)}
                          // onClick={generarReporte}
                          style={{
                            height: '35px',
                            width: '120px'
                          }}
                        />
                      </Col> */}

                      {/* <Col xs={1} style={{ textAlign: 'right' }}>
                        <Form.Label className="mb-0">TipoOpe</Form.Label>
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            setTipoOperacion(parseInt(e.target.value))
                          }
                          className="form-select form-select-sm"
                          style={{
                            height: '35px',
                            width: '110px'
                          }}
                        >
                          <option value="1">Precuenta</option>
                          <option value="2">Cheques</option>
                        </Form.Control>
                      </Col> */}

                      <Col xs={2}>
                        <Form.Control
                          type="text"
                          size="sm"
                          placeholder="Search..."
                          onChange={filtraReporte}
                          style={{
                            height: '35px',
                            width: '230px',
                            padding: '5px',
                            marginLeft: '65px'
                          }}
                        />
                      </Col>
                      <Col xs={1} style={{ textAlign: 'right' }}>
                        <Button
                          variant="primary"
                          size="sm"
                          onClick={generarReporte1}
                          style={{
                            height: '35px',
                            width: '80px',
                            padding: '5px',
                            marginLeft: '230px'
                          }}
                        >
                          <BsSearch /> Buscar
                        </Button>
                      </Col>

                      <Col xs={1}>
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={handleOpenModal}
                          style={{
                            height: '35px',
                            width: '70px',
                            padding: '5px',
                            backgroundColor: 'orange',
                            marginLeft: '300px'
                          }}
                        >
                          <CgAdd />
                        </Button>
                      </Col>
                      <Col xs={2}>
                        <Button
                          size="sm"
                          variant="success"
                          onClick={downloadExcel}
                          style={{
                            height: '35px',
                            width: '70px',
                            padding: '5px',
                            marginLeft: '370px'
                          }}
                        >
                          <BsFileEarmarkExcel />
                          &nbsp;Excel
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            </div>
            <Row>
              <Col xs={3}></Col>
              <Col xs={6}>
                <Card>
                  <DataTable
                    noHeader
                    defaultSortFieldId={'id'}
                    defaultSortAsc={true}
                    striped={true}
                    dense={true}
                    paginationPerPage={10}
                    pagination
                    highlightOnHover
                    columns={columnsRelacionBookPrecta}
                    data={filteredData}
                    pointerOnHover
                  />
                </Card>
              </Col>
              <Col xs={3}></Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Relación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Precuenta</Form.Label>
              <Form.Control
                type="text"
                value={newPrecuenta}
                onChange={(e) => setNewPrecuenta(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cheques</Form.Label>
              <Form.Control
                type="text"
                value={newCheques}
                onChange={(e) => setNewCheques(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Guardar
          </Button>
        </Modal.Footer>

        {/* <Col xs={1} style={{ textAlign: 'right' }}>
                        <Form.Label className="mb-0">TipoOpe</Form.Label>
                      </Col>
                      <Col xs={2}>
                        <Form.Control
                          as="select"
                          onChange={(e) =>
                            setTipoOperacion(parseInt(e.target.value))
                          }
                          className="form-select form-select-sm"
                          style={{
                            height: '35px',
                            width: '110px'
                          }}
                        >
                          <option value="1">Precuenta</option>
                          <option value="2">Cheques</option>
                        </Form.Control>
                      </Col> */}
      </Modal>

      <MsgInformativo
        show={show}
        msg={msg}
        header={header}
        msgColor={msgColor}
        closeToast={() => {
          setShowMsg(false)
        }}
      />
    </div>
  )
}
