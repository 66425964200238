import { createSlice } from '@reduxjs/toolkit';

const rptRelacionBookPrectaSlice = createSlice({
  name: 'rptRelacionBookPrecta',
  initialState: {},
  reducers: {
    // define tus reducers aquí
  }
});

export const { /* exporta tus acciones aquí */ } = rptRelacionBookPrectaSlice.actions;
export default rptRelacionBookPrectaSlice.reducer;
