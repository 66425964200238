import React, { FC, useEffect } from 'react'
import { Button, Modal, Toast, ToastContainer } from 'react-bootstrap'
import { propTypes } from 'react-bootstrap/esm/Image'
// import { IPerfil } from '../../../../Interfaces/Catalogos/IPerfiles'
// import pefilesMenuServices from '../../../../Services/Catalogos/PerfilesMenu.Services'
import perfilesMenuServices from '../../../../Services/Catalogos/PerfilesMenu.Services'
import DTOPerfilCreate from '../../../../DTO/DTOPerfilCreate'

import IPerfilesMenu from '../../../../interfaces/IPerfilesMenu'
import IItemMenu from '../../../../interfaces//Catalogos/IItemMenu'

interface IProps {
  show: boolean
  ItemMenu: number
  CloseModalPerfiles: (arg: boolean) => void
}

export const NuevoItemMenu: FC<IProps> = (props) => {
  const [ItemMenu, setItemMenu] = React.useState(props.ItemMenu)
  const [descripcion, setDescripcion] = React.useState('')
  const [posicion, setPosicion] = React.useState(1)
  const [url, setUrl] = React.useState('')
  const [MenuItems, setMenuItems] = React.useState<IPerfilesMenu[]>()
  const [msgError, setMsgError] = React.useState('')
  const [msgDialog, setMsgDialog] = React.useState(false)

  useEffect(() => {
    console.log('props=' + JSON.stringify(props))
  }, [props])

  useEffect(() => {
    perfilesMenuServices
      .getMenu()
      .then((response) => {
        setMenuItems(response.data)
        console.log(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
      })
    setItemMenu(props.ItemMenu)
  }, [props.show])

  function validatePerfil() {
    var msg = 'Proporcione: '
    if (!descripcion.length) msg += 'la descripcion / '
    if (!url.length) msg += 'la URL'
    if (msg != 'Proporcione: ') {
      setMsgError(msg)
      setMsgDialog(true)
    }
    const data: IItemMenu = {
      id: 0,
      padreId: ItemMenu,
      posicion: posicion,
      descripcion: descripcion,
      url: url,
    }

    perfilesMenuServices
      .createItemMenu(data)
      .then((response) => {
        if (response.status == 200)
          alert('El item de menu se creo existosamente!')
        console.log(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
      })
    console.log(ItemMenu + ' ' + descripcion + ' ' + posicion + ' ' + url)
  }

  const onChangeItem = (e: any): void => {
    //value: number = e.currentTarget.value;
    setItemMenu(e.currentTarget.value)
  }

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Nuevo item de menu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <form>
            <div className='mb-3'>
              <label className='form-label'>El item sera hijo de:</label>
              <select
                className='form-select'
                aria-label='Perfiles'
                defaultValue={ItemMenu}
                onChange={onChangeItem}
                value={ItemMenu}
              >
                <option value='0'>Menu</option>
                {MenuItems?.map((m) => {
                  return m.id === props.ItemMenu ? (
                    <option value={m.id}>{m.descripcion}</option>
                  ) : null
                })}
              </select>
            </div>
            <div className='mb-3'>
              <label className='form-label'>Descripcion</label>
              <input
                className='form-control'
                id='Descripcion'
                aria-describedby='Descripcion'
                onChange={(e) => {
                  setDescripcion(e.currentTarget.value)
                }}
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Posicion</label>
              <select
                className='form-select'
                aria-label='Perfiles'
                onChange={(e) => {
                  setPosicion(parseInt(e.currentTarget.value))
                }}
              >
                {[...Array(41)].map((x, i) =>
                  i > 0 ? <option value={i}>{i}</option> : null
                )}
              </select>
            </div>
            <div className='mb-3'>
              <label className='form-label'>Url</label>
              <input
                className='form-control'
                id='url'
                aria-describedby='url'
                onChange={(e) => {
                  setUrl(e.target.value)
                }}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className='row'>
          <div className='col'>
            <Button
              variant='secondary'
              onClick={() => {
                props.CloseModalPerfiles(false)
              }}
            >
              Cerrar
            </Button>
          </div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'></div>
          <div className='col'>
            <Button
              variant='primary'
              onClick={() => {
                //props.createProfile(true);
                validatePerfil()
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
      </Modal.Footer>
      <ToastContainer position={'middle-center'}>
        <Toast
          onClose={() => setMsgDialog(false)}
          show={msgDialog}
          delay={3000}
          bg='dark'
          autohide
        >
          <Toast.Header>
            <strong className='me-auto'>Error!</strong>
          </Toast.Header>
          <Toast.Body style={{ backgroundColor: '#F8F9F9' }}>
            {msgError}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </Modal>
  )
}
