import React, { FC, useEffect } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import IPerfilesMenu from '../../../interfaces//IPerfilesMenu'
import perfilesMenuServices from '../../../Services/Catalogos/PerfilesMenu.Services'
import { IPerfil } from '../../../interfaces/Catalogos/IPerfiles'
import { NuevoPerfil } from './nuevoPerfil/NuevoPerfil'
import { NuevoItemMenu } from './nuevoItemMenu/NuevoItemMenu'
import DTOItemMenuPerfil from '../../../DTO/DTOItemMenuPerfil'
import '../../../css/menu2.css'
import '../../../css/generic01.css'

interface IProps {
  show: boolean
  CloseModal: (arg: boolean) => void
  CloseModalPerfiles: (arg: boolean) => void
}

interface IPropsChild {
  item: IPerfilesMenu
  menu: IPerfilesMenu[]
  IdPerfil: number
}

const MenuChild: React.FC<IPropsChild> = (props) => {
  const [DialogNewItemMenu, setDialogNewItemMenu] = React.useState(false)
  const [ItemMenu, setItemMenu] = React.useState(0)
  function addItemMenu(id: number, e: any) {
    const data: DTOItemMenuPerfil = {
      IdPerfil: props.IdPerfil,
      itemMenu: id,
      asignado: e.currentTarget.checked,
    }
    perfilesMenuServices
      .toggleItemMenu(data)
      .then((response) => {})
      .catch((e: Error) => {})
  }

  const createItemMenu = (value: boolean): void => {
    alert('crea item menu')
  }

  const CloseModalItemMenu = (show: boolean): void => {
    setDialogNewItemMenu(false)
  }

  return (
    <>
      <li>
        <input
          className='form-check-input'
          type='checkbox'
          value=''
          defaultChecked={props.item.agrupado == 1 ? true : false}
          id={'Chk' + props.item.id}
          onClick={(e) => {
            addItemMenu(props.item.id, e)
          }}
        />
        &nbsp;
        <a href='javascript:;'>
          {props.item.descripcion}
          <span
            onClick={() => {
              setItemMenu(props.item.id)
              setDialogNewItemMenu(true)
            }}
          >
            ... +
          </span>
        </a>
        <ul>
          {props.menu.map((m, index) =>
            m.padreId === props.item.id ? (
              <li>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  defaultChecked={m.agrupado == 1 ? true : false}
                  id={'Chk' + m.id}
                  onClick={(e) => {
                    addItemMenu(m.id, e)
                  }}
                />
                &nbsp;
                <a href='javascript:;'>
                  {m.descripcion}
                  <span
                    onClick={() => {
                      setItemMenu(m.id)
                      setDialogNewItemMenu(true)
                    }}
                  >
                    ... ++
                  </span>
                </a>
                <ul>
                  {props.menu
                    .filter((m2) => m2.padreId === m.id)
                    .map((m2, index) => (
                      <li>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          defaultChecked={m2.agrupado == 1 ? true : false}
                          id={'Chk_lv2' + m2.id}
                          onClick={(e) => {
                            addItemMenu(m2.id, e)
                          }}
                        />
                        &nbsp;
                        <a href=''>{m2.descripcion}</a>
                      </li>
                    ))}
                </ul>
              </li>
            ) : (
              ''
            )
          )}
        </ul>
      </li>
      <NuevoItemMenu
        show={DialogNewItemMenu}
        ItemMenu={ItemMenu}
        CloseModalPerfiles={CloseModalItemMenu}
      />
    </>
  )
}

export const AdmonPerfiles: React.FC<IProps> = (props) => {
  const [sDialog, setsDialog] = React.useState(true)
  const [DialogNewProfile, setDialogNewProfile] = React.useState(false)
  const [Menu, setMenu] = React.useState<IPerfilesMenu[]>()
  const [IdPerfil, setIdPerfil] = React.useState(0)
  const [Perfiles, setPerfiles] = React.useState<IPerfil[]>()
  useEffect(() => {
    perfilesMenuServices
      .getAllPerfiles()
      .then((response) => {
        setPerfiles(response.data)
      })
      .catch((e: Error) => {})
    loadPerfil(0)
  }, [props.show])

  function loadPerfil(id: number) {
    setMenu([])
    perfilesMenuServices
      .getPerfilMenuById(id)
      .then((response) => {
        setMenu(response.data)
      })
      .catch((e: Error) => {
        console.log(e)
      })
  }

  const createProfile = (value: boolean): void => {
    alert('crea nuevo perfil')
  }

  const CloseModalPerfiles = (show: boolean): void => {
    setDialogNewProfile(false)
  }

  const onChangePerfil = (e: any): void => {
    setIdPerfil(e.currentTarget.value)
    loadPerfil(e.currentTarget.value)
  }

  return props.show ? (
    <>
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName=''
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            <div className='container'>
              <div className='row'>Administrador de perfiles</div>
              <div className='row'>
                <div className='col-auto'>
                  <select
                    className='form-select'
                    aria-label='Perfiles'
                    onChange={onChangePerfil}
                  >
                    {Perfiles?.map((p) => {
                      return <option value={p.id}>{p.perfil}</option>
                    })}
                  </select>
                </div>
                <div className='col'></div>
                <div className='col-auto'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      setDialogNewProfile(true)
                    }}
                  >
                    Nuevo
                  </button>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='divPerfiles'>
          <div className='tree' style={{ backgroundColor: '#FFFFFF' }}>
            <ul>
              <li>
                <a href='#'>Menu</a>
                <ul>
                  {Menu?.map((item, index) =>
                    item.padreId === 0 ? (
                      <MenuChild menu={Menu} item={item} IdPerfil={IdPerfil} />
                    ) : (
                      ''
                    )
                  )}
                </ul>
              </li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              props.CloseModal(false)
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <NuevoPerfil
        show={DialogNewProfile}
        createProfile={createProfile}
        CloseModalPerfiles={CloseModalPerfiles}
      />
    </>
  ) : null
}
