import http from "../common/http-common";
import ILogin from "../../interfaces/Ilogin"
import ItemMenuData  from "../../interfaces/Catalogos/IItemMenu";
import Token from '../../interfaces/token'
import IRespuesta from "../../interfaces/IRespuesta";
import { DTOPINUsuario } from "../../DTO/DTOPINUsuario";
import DTOUsuario from "../../DTO/DTOUsuario";
import { DTOResetPassword } from "../../DTO/DTOResetPassword";

class authDataService {

  constructor() {
   // super();
    type token = string;
    type returnData = [ItemMenuData[], Token]    
  }
  login(data: ILogin) {
    return http.post<[ItemMenuData[], Token]>("/Auth", data);
  }
  // loginTransaport(data: ILogin) {
  //   return http.post<[ItemMenuData[], Token]>("/Auth/AreYouTransport", data);
  // }
  forgotPassword(data: ILogin) {
    return http.post<IRespuesta>("/Auth/forgotPassword", data);
  }
  validatePIN(data: DTOPINUsuario) {
    return http.post<IRespuesta>("/Auth/validatePIN", data);
  }
  resetPassword(data: DTOResetPassword) {
    return http.post<DTOUsuario>("/Auth/resetPassword", data);
  }
}
export default new authDataService();



