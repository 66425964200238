import { configureStore } from '@reduxjs/toolkit';
import userStatusReducer from './features/userStatusSlice/userStatusSlice'
// import Cat1868DestinosSlice from './features/Clientes/1868/Cat1868DestinosSlice'
// import Cat1868ResponsablesSlice from './features/Clientes/1868/Cat1868ResponsablesSlice'
// import Cat1868ClaveSATSlice from './features/Clientes/1868/Cat1868ClaveSATSlice'
// import Data1868FacturaDetalleSlice from './features/Clientes/1868/Data1868FacturaDetalleSlice'
// import CatTransfersSlice from './features/Clientes/1868/CatTransfersSlice'
import CatRelacionBookPrectaSlice from './features/CatRelacionBookPrectaSlice'
import CatNotificacionesSlice from './features/Clientes/1868/CatNotificaciones';


const store = configureStore({
  reducer: {
    userStatus: userStatusReducer,
    // Cat1868Destinos: Cat1868DestinosSlice,
    // Cat1868Responsables: Cat1868ResponsablesSlice,
    // Cat1868ClaveSAT: Cat1868ClaveSATSlice,
    // Data1868FacturaDetalle: Data1868FacturaDetalleSlice,
    // CatTransfers: CatTransfersSlice,
    
    CatRelacionBookPrectaSlice: CatRelacionBookPrectaSlice, 
    CatNotificaciones: CatNotificacionesSlice

    

  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store









// -- codigp de componente de lso archivos 
// // src/components/ExcelUploader.tsx
// import React, { useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import * as XLSX from 'xlsx';

// const ExcelUploader: React.FC = () => {
//   const [bookFile, setBookFile] = useState<File | null>(null);
//   const [prectaFile, setPrectaFile] = useState<File | null>(null);
//   const [bookData, setBookData] = useState<any[]>([]);
//   const [prectaData, setPrectaData] = useState<any[]>([]);

//   const onDropBook = (acceptedFiles: File[]) => {
//     setBookFile(acceptedFiles[0]);
//     readExcelFile(acceptedFiles[0], setBookData);
//   };

//   const onDropPrecta = (acceptedFiles: File[]) => {
//     setPrectaFile(acceptedFiles[0]);
//     readExcelFile(acceptedFiles[0], setPrectaData);
//   };

//   const readExcelFile = (file: File, setData: React.Dispatch<React.SetStateAction<any[]>>) => {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       const binaryStr = e.target?.result;
//       if (binaryStr) {
//         const workbook = XLSX.read(binaryStr, { type: 'binary' });
//         const sheetName = workbook.SheetNames[0];
//         const sheet = workbook.Sheets[sheetName];
//         const data = XLSX.utils.sheet_to_json(sheet);
//         setData(data);
//       }
//     };
//     reader.readAsBinaryString(file);
//   };

//   return (
//     <div>
//       <h2>Cargar archivos Excel</h2>
//       <div style={{ display: 'flex', justifyContent: 'space-between' }}>
//         <div>
//           <h3>Book</h3>
//           <Dropzone onDrop={onDropBook} />
//           {bookFile && <p>Archivo cargado: {bookFile.name}</p>}
//         </div>
//         <div>
//           <h3>Precta</h3>
//           <Dropzone onDrop={onDropPrecta} />
//           {prectaFile && <p>Archivo cargado: {prectaFile.name}</p>}
//         </div>
//       </div>
//       <div>
//         <h3>Datos de Book</h3>
//         <pre>{JSON.stringify(bookData, null, 2)}</pre>
//       </div>
//       <div>
//         <h3>Datos de Precta</h3>
//         <pre>{JSON.stringify(prectaData, null, 2)}</pre>
//       </div>
//     </div>
//   );
// };

// interface DropzoneProps {
//   onDrop: (acceptedFiles: File[]) => void;
// }

// const Dropzone: React.FC<DropzoneProps> = ({ onDrop }) => {
//   const { getRootProps, getInputProps } = useDropzone({ onDrop });

//   return (
//     <div {...getRootProps()} style={dropzoneStyle}>
//       <input {...getInputProps()} />
//       <p>Arrastra un archivo aquí, o haz clic para seleccionar un archivo</p>
//     </div>
//   );
// };

// const dropzoneStyle: React.CSSProperties = {
//   border: '2px dashed #007bff',
//   borderRadius: '5px',
//   padding: '20px',
//   textAlign: 'center',
//   cursor: 'pointer',
//   width: '200px',
//   height: '100px'
// };

// export default ExcelUploader;
